import React from "react";
import { useConfiguration } from "./configuration-provider";
import axios, { AxiosInstance } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export interface AuthUser {
  userId: string;
  userPrincipalName: string;
}

/*
 * Our State.
 */
export interface IServiceInterface {
  getDataApi: () => Promise<AxiosInstance>;
}

export const ServiceProviderContext = React.createContext<IServiceInterface>(
  {} as IServiceInterface
);

/*
 * Our provider.
 */
export interface IServiceProviderProps {
  children: React.ReactNode;
}

export const RiderServiceProvider: React.FunctionComponent<
  IServiceProviderProps
> = ({ children }) => {
  const config = useConfiguration();
  const { getAccessTokenSilently } = useAuth0();

  const baseURL = `${config.apiUrl}`;
  const dataApi = React.useMemo(() => {
    return axios.create({
      baseURL,
    });
  }, [baseURL]);

  /*
   * Allows a child of this provider to access the Bearer token on demand.
   */
  const getDataApi = React.useCallback((): Promise<AxiosInstance> => {
    return new Promise((resolve) => {
      getAccessTokenSilently({
        authorizationParams: {
          audience: "https://api.canvassnow.com",
          scope: "openid profile email",
        },
      }).then((response) => {
        dataApi.defaults.headers.common["Authorization"] = `Bearer ${response}`;
        resolve(dataApi);
      });
    });
  }, [dataApi, getAccessTokenSilently]);

  /*
   * Provide our getToken function to our consumers
   */
  const contextValues = React.useMemo(
    () => ({
      getDataApi,
    }),
    [getDataApi]
  );

  return (
    <ServiceProviderContext.Provider value={contextValues}>
      {children}
    </ServiceProviderContext.Provider>
  );
};

/*
 * Our hook.
 */
export const useRiderServiceProvider = (): IServiceInterface =>
  React.useContext(ServiceProviderContext);

export default RiderServiceProvider;
