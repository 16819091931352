import "./../App.css";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import LoadingSpinner from "../shell/loading-spinner";
import { useRiderServiceProvider } from "../shell/rider-service-provider";
import { useLocation } from "../shell/location-provider";
import { UsersModelWithError } from "../models/users.model";

interface VolunteerListPageProps {}

export const VolunteerListPage: React.FunctionComponent<VolunteerListPageProps> = () => {
  const { getDataApi } = useRiderServiceProvider();
  const currentLocation = useLocation();
  const [stageData, setStageData] = React.useState<UsersModelWithError | undefined>(undefined);

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<UsersModelWithError>(`volunteers`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  const volunteersToShow = stageData.entityResult.map((item, i) => (
    <Col md={6} lg={4} key={i}>
      <div
        style={{
          height: "55px",
          marginBottom: "4px",
          backgroundColor: "#032742",
          color: "white",
          fontSize: "18px",
          borderRadius: "4px",
          borderBottomWidth: "1px",
          borderBottomColor: "darkgray",
          borderBottomStyle: "solid",
          padding: "10px",
          paddingTop: "5px",
        }}
      >
        <Link style={{ color: "white", textDecoration: "none" }} to={`${item.id}`}>
          <div>
            <span style={{ lineHeight: "46px" }}>{item.firstName + " " + item.lastName}</span>
            <span
              style={{
                float: "right",
                fontWeight: "500",
                fontSize: "22px",
                lineHeight: "44px",
              }}
            ></span>
          </div>
        </Link>
      </div>
    </Col>
  ));

  return (
    <div>
      {stageData.entityResult.length === 0 && (
        <div>No volunteers found. Please click the Add button below to add your volunteer.</div>
      )}
      {stageData.entityResult.length > 0 && (
        <div>
          <div style={{ height: "34px", marginBottom: "10px" }}>
            <span style={{ fontSize: "25px", marginRight: "10px" }}>Volunteers</span>
            <span style={{ display: "inline-block", verticalAlign: "bottom", marginBottom: "4px", fontSize: "13px" }}>
              (Click to edit)
            </span>
          </div>
          <Container>
            <Row>{volunteersToShow}</Row>
          </Container>
        </div>
      )}
      {currentLocation.latitude !== 0 && (
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            margin: "6px",
            left: "0%",
            width: "100%",
            backgroundColor: "white",
            borderTopWidth: "1px",
            borderTopColor: "darkgray",
            borderTopStyle: "solid",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Link
              style={{ float: "right", marginRight: "20px", marginBottom: "1px" }}
              className="pc-linkbutton"
              to={`0`}
            >
              Add
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default VolunteerListPage;
