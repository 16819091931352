import "./../App.css";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink, Route, Routes } from "react-router-dom";
import LoadingSpinner from "./loading-spinner";
import { Nav, Navbar } from "react-bootstrap";
import ContentHost from "./content-host";
import CollectListPage from "../collect/collect-list-page";
import CollectEditPage from "../collect/collect-edit-page";
import CollectDeletePage from "../collect/collect-delete-page";
import VolunteerListPage from "../volunteers/volunteer-list-page";
import VolunteerEditPage from "../volunteers/volunteer-edit-page";

interface UserBannerProps {}

export const UserBanner: React.FunctionComponent<UserBannerProps> = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading === true) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Navbar
        className="navbar-customclass"
        variant="dark"
        expand="lg"
        style={{
          fontSize: "16px",
        }}
      >
        <Navbar.Brand href="#home" style={{ paddingLeft: "4px" }}>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              margin: "0px",
            }}
          >
            <img
              style={{ width: "100%", maxWidth: "80px", marginBottom: "0px", marginRight: "15px" }}
              src="/burgum-logo.png"
              alt="Burgum Logo"
            />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to={`/user/dontations`} className={({ isActive }) => (isActive ? "active-link" : "inactive-link")}>
              Donations
            </NavLink>
            <NavLink to={`/user/volunteers`} className={({ isActive }) => (isActive ? "active-link" : "inactive-link")}>
              Volunteers
            </NavLink>
            <NavLink to={`/noauth/logout`} className={({ isActive }) => (isActive ? "active-link" : "inactive-link")}>
              Logout
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {isAuthenticated && (
        <Routes>
          <Route path="*" element={<ContentHost />}>
            <Route path="donations" element={<CollectListPage />}></Route>
            <Route path="donations/:donationId" element={<CollectEditPage />}></Route>
            <Route path="donations/:donationId/delete" element={<CollectDeletePage />}></Route>
            <Route path="volunteers" element={<VolunteerListPage />}></Route>
            <Route path="volunteers/:userId" element={<VolunteerEditPage />}></Route>
          </Route>
        </Routes>
      )}
    </>
  );
};

export default UserBanner;
