import "./../App.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./loading-spinner";
import { useRiderServiceProvider } from "./rider-service-provider";
import { UserModelWithError } from "../models/users.model";
import { LogoutButton } from "./login";
import UserBanner from "./user-banner";
import { useLocation } from "./location-provider";

interface UserRootProps {}

// Anything under UserRoot will be authenticated.
export const UserRoot: React.FunctionComponent<UserRootProps> = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<UserModelWithError>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<UserModelWithError>(`user/current`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  if (stageData.errorCode === "daed46c7-5586-4822-ae83-40080fae5d1a") {
    return (
      <div>
        Your account is not associated with any campaigns. Please contact the campaign administrator.
        <LogoutButton />
      </div>
    );
  }

  if (
    stageData.errorCode === "00000000-0000-0000-0000-000000000000" &&
    stageData.entityResult.emailVerified === false
  ) {
    return (
      <div
        style={{
          textAlign: "center",
          margin: "20px",
        }}
      >
        <img style={{ width: "80%", marginBottom: "20px" }} src="/burgum-logo-whitebg.png" alt="Burgum Logo" />
        <div>Your account has not been verified. Please check your email for a verification link.</div>
        <button style={{ marginTop: "30px", marginBottom: "10px" }} className="pc-button" onClick={() => navigate(0)}>
          Check Again
        </button>
      </div>
    );
  }

  if (currentLocation !== undefined && (currentLocation.latitude === undefined || currentLocation.latitude === 0)) {
    return (
      <div
        style={{
          textAlign: "center",
          margin: "20px",
        }}
      >
        <img style={{ width: "80%", marginBottom: "20px" }} src="/burgum-logo-whitebg.png" alt="Burgum Logo" />
        <div>This app needs your location to function. Please turn on location services.</div>
        <button style={{ marginTop: "30px", marginBottom: "10px" }} className="pc-button" onClick={() => navigate(0)}>
          Check Again
        </button>
      </div>
    );
  }

  if (stageData.errorCode === "00000000-0000-0000-0000-000000000000" && stageData.entityResult.emailVerified === true) {
    return (
      <div>
        <UserBanner />
      </div>
    );
  }

  return (
    <div>
      Unknown error.
      <LogoutButton />
    </div>
  );
};

export default UserRoot;
