import React from "react";
import "./../App.css";
import { useAuth0 } from "@auth0/auth0-react";

interface LoginPageProps {}

export const LoginPage: React.FunctionComponent<LoginPageProps> = () => {
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <></>;
};

export default LoginPage;
