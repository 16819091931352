import React from "react";
import { DeployConfig } from "../deployConfig";

/*
 * Our State.
 */
export interface IRiderConfiguration {
  apiUrl: string;
}

export const ConfigurationProviderContext =
  React.createContext<IRiderConfiguration>({} as IRiderConfiguration);

/*
 * Our provider.
 */
export interface IConfigurationProviderProps {
  children: React.ReactNode;
}

export const ConfigurationProvider: React.FunctionComponent<
  IConfigurationProviderProps
> = ({ children }) => {
  const contextValues = React.useMemo(
    () => ({
      apiUrl: DeployConfig.getCurrent().apiUrl,
    }),
    []
  );

  return (
    <ConfigurationProviderContext.Provider value={contextValues}>
      {children}
    </ConfigurationProviderContext.Provider>
  );
};

/*
 * Our hook.
 */
export const useConfiguration = (): IRiderConfiguration =>
  React.useContext(ConfigurationProviderContext);

export default ConfigurationProvider;
