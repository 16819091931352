import "./../App.css";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRiderServiceProvider } from "../shell/rider-service-provider";
import { DonationModel } from "../models/donations.model";
import LoadingSpinner from "../shell/loading-spinner";

interface CollectDeletePageProps {}

export const CollectDeletePage: React.FunctionComponent<CollectDeletePageProps> = () => {
  const navigate = useNavigate();
  const { donationId } = useParams();
  const { getDataApi } = useRiderServiceProvider();
  const [donation, setDonation] = useState<DonationModel | undefined>(undefined);

  React.useEffect(() => {
    if (donationId !== undefined) {
      getDataApi().then((dataApi) => {
        dataApi
          .get<DonationModel>(`user/donations/${donationId}`)
          .then((res) => {
            setDonation(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, donationId]);

  const deleteDonation = React.useCallback(
    (donationId: string | undefined) => {
      getDataApi().then((dataApi) => {
        dataApi
          .delete(`user/donations/${donationId}`)
          .then((res) => {
            navigate(`/user/donations/`);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    [getDataApi, navigate]
  );

  if (donation === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "60px", fontSize: "25px" }}>
        <div style={{ marginTop: "60px" }}>Are you sure you wish to delete this donation?</div>
        <div style={{ marginTop: "100px" }}>{donation.firstName + " " + donation.lastName}</div>
        <div style={{ marginTop: "0px", fontSize: "40px" }}>${donation.amount}</div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          margin: "6px",
          left: "0%",
          width: "100%",
          borderTopWidth: "1px",
          borderTopColor: "darkgray",
          borderTopStyle: "solid",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <button
            style={{ float: "right", marginRight: "15px" }}
            className="pc-button"
            onClick={() => deleteDonation(donationId)}
          >
            Delete
          </button>
          <Link
            style={{ float: "right", marginRight: "15px" }}
            className="pc-linkbutton"
            to={`/user/donations/${donationId}`}
          >
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CollectDeletePage;
