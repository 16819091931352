import "./App.css";
import "@progress/kendo-theme-default/dist/all.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ConfigurationProvider from "./shell/configuration-provider";
import RiderServiceProvider from "./shell/rider-service-provider";
import RootRedirect from "./shell/root-redirect";
import NotAuthenticatedPage from "./shell/not-authenticated-page";
import NewAccountPage from "./shell/new-account-page";
import LoginPage from "./shell/login-page";
import UserRoot from "./shell/user-root";
import LogoutPage from "./shell/logout-page";
import LocationProvider from "./shell/location-provider";
import UserRedirect from "./shell/user-redirect";

function App() {
  return (
    <div className="App">
      <ConfigurationProvider>
        <RiderServiceProvider>
          <LocationProvider>
            <Routes>
              <Route path="/" element={<RootRedirect />} />
              <Route path="/noauth" element={<NotAuthenticatedPage />} />
              <Route path="/noauth/newaccount" element={<NewAccountPage />} />
              <Route path="/noauth/login" element={<LoginPage />} />
              <Route path="/noauth/logout" element={<LogoutPage />} />
              <Route path="/user" element={<UserRedirect />} />
              <Route path="/user/*" element={<UserRoot />} />
            </Routes>
          </LocationProvider>
        </RiderServiceProvider>
      </ConfigurationProvider>
    </div>
  );
}

export default App;
