import "./../App.css";
import React from "react";
import Spinner from "react-bootstrap/Spinner";

interface LoadingSpinnerProps {}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = () => {
  return (
    <div style={{ height: "50px", width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Spinner
          style={{ position: "fixed", top: "50%", right: "50%" }}
          animation="border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

export default React.memo(LoadingSpinner) as typeof LoadingSpinner;
