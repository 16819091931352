import React from "react";
import "./../App.css";
import { useAuth0 } from "@auth0/auth0-react";

interface LogoutPageProps {}

export const LogoutPage: React.FunctionComponent<LogoutPageProps> = () => {
  const { logout } = useAuth0();

  React.useEffect(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return <></>;
};

export default LogoutPage;
