import "./../App.css";
import React from "react";
import { Outlet } from "react-router-dom";

interface ContentHostProps {}

const ContentHost: React.FunctionComponent<ContentHostProps> = () => {
  return (
    <>
      <div style={{ margin: "20px", marginTop: "14px" }}>
        <Outlet></Outlet>
      </div>
    </>
  );
};

export default React.memo(ContentHost) as typeof ContentHost;
