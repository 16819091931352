import "./../App.css";
import axios from "axios";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import LoadingSpinner from "./loading-spinner";
import { useConfiguration } from "./configuration-provider";
import { UserCreateModel, UserModelWithError } from "../models/users.model";

interface NewAccountPageProps {}

export const NewAccountPage: React.FunctionComponent<
  NewAccountPageProps
> = () => {
  const config = useConfiguration();
  const navigate = useNavigate();
  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const [validated, setValidated] = useState(false);
  const [triggerSave, setTriggerSave] = useState(false);
  const [newUser, setNewUser] = useState<UserCreateModel | undefined>(
    undefined
  );

  const baseURL = `${config.apiUrl}`;
  const dataApi = React.useMemo(() => {
    return axios.create({
      baseURL,
    });
  }, [baseURL]);

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setNewUser({
        email: inputEmailRef.current!.value,
        password: inputPasswordRef.current!.value,
      });
      setTriggerSave(true);
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  React.useEffect(() => {
    if (triggerSave === true) {
      dataApi
        .post<UserModelWithError>(`user`, newUser)
        .then((res) => {
          if (res.data.errorCode === "bfafc8a1-1378-4b6d-ab73-e8a161f80537") {
            alert(
              "Sorry this email is not approved for any campaigns. Please contact the campaign administrator."
            );
            setTriggerSave(false);
          }
          if (res.data.errorCode === "e45e45c9-578c-4178-9b68-2333cb92e72f") {
            alert("This email already has an account setup, please login.");
            navigate("../");
            setTriggerSave(false);
          }
          if (res.data.errorCode === "32aeb487-203d-404d-a595-de843218d02d") {
            alert("Your password is too weak, please create a new password.");
            setTriggerSave(false);
          }
          if (res.data.errorCode === "c35990f7-8bc2-4e8f-87d6-b607965b9afa") {
            // user already exists in Auth0, but no authcode in our db.  Login will fix that
            navigate("/noauth/login");
            setTriggerSave(false);
          }
          if (res.data.errorCode === "00000000-0000-0000-0000-000000000000") {
            navigate("/noauth/login");
            setTriggerSave(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dataApi, triggerSave, newUser, navigate]);

  if (triggerSave === true) {
    return <LoadingSpinner />;
  }

  return (
    <div
      style={{
        textAlign: "center",
        margin: "20px",
      }}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            ref={inputEmailRef}
            placeholder="name@example.com"
          />
          <Form.Control.Feedback type="invalid">
            Please provide an email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            ref={inputPasswordRef}
            placeholder="password"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a password.
          </Form.Control.Feedback>
        </Form.Group>
        <div>
          Your password must be at least 8 characters long, contain at least one
          uppercase letter, one lowercase letter and one number.
        </div>{" "}
        <br />
        <Button className="pc-button" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewAccountPage;
