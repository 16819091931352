import "./../App.css";
import React from "react";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "./loading-spinner";
import { useRiderServiceProvider } from "./rider-service-provider";
import { UserModelWithError } from "../models/users.model";

interface UserRedirectProps {}

// We have logged in and the user came to the root of the site.  RootRedirect sent them here because they are authenticated.
export const UserRedirect: React.FunctionComponent<UserRedirectProps> = () => {
  const { getDataApi } = useRiderServiceProvider();
  const [stageData, setStageData] = React.useState<UserModelWithError>();

  React.useEffect(() => {
    getDataApi().then((dataApi) => {
      dataApi
        .get<UserModelWithError>(`user/current`)
        .then((res) => {
          setStageData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }, [getDataApi]);

  if (stageData === undefined) {
    return <LoadingSpinner />;
  }

  if (stageData.entityResult.isAdmin === true) {
    return <Navigate to={"volunteers"} />;
  }

  return <Navigate to={"donations"} />;
};

export default UserRedirect;
