import React, { useState } from "react";
import { useRiderServiceProvider } from "./rider-service-provider";
import { LocationModel } from "../models/users.model";

/*
 * Our State.
 */
export interface ILocationProvidedData {
  latitude: number | undefined;
  longitude: number | undefined;
  city?: string;
  region?: string;
}

export const LocationProviderContext = React.createContext<ILocationProvidedData>({} as ILocationProvidedData);

/*
 * Our provider.
 */
export interface ILocationProviderProps {
  children: React.ReactNode;
}

export const LocationProvider: React.FunctionComponent<ILocationProviderProps> = ({ children }) => {
  const { getDataApi } = useRiderServiceProvider();
  const [city, setCity] = useState<string | undefined>();
  const [region, setRegion] = useState<string | undefined>();
  const [latitude, setLatitude] = useState<number | undefined>();
  const [longitude, setLongitude] = useState<number | undefined>();

  navigator.geolocation.getCurrentPosition(
    (position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    },
    (err) => console.log(err)
  );

  React.useEffect(() => {
    if (latitude !== undefined && latitude !== 0) {
      getDataApi().then((dataApi) => {
        dataApi
          .get<LocationModel>(`user/geolocate?latitude=${latitude}&longitude=${longitude}`)
          .then((res) => {
            setCity(res.data.locality);
            setRegion(res.data.region);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, latitude, longitude]);

  const contextValues = React.useMemo(
    () => ({
      latitude: latitude,
      longitude: longitude,
      city: city,
      region: region,
    }),
    [latitude, longitude, city, region]
  );

  return <LocationProviderContext.Provider value={contextValues}>{children}</LocationProviderContext.Provider>;
};

/*
 * Our hook.
 */
export const useLocation = (): ILocationProvidedData => React.useContext(LocationProviderContext);

export default LocationProvider;
