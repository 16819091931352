import React from "react";
import "./../App.css";
import { Link } from "react-router-dom";

interface NotAuthenticatedPageProps {}

export const NotAuthenticatedPage: React.FunctionComponent<NotAuthenticatedPageProps> = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "20px",
      }}
    >
      <img
        style={{ width: "80%", maxWidth: "220px", marginBottom: "20px" }}
        src="/burgum-logo-whitebg.png"
        alt="Burgum Logo"
      />
      <div>First time user?</div> <div>Please create an account.</div>
      <Link className="pc-linkbutton" to={`newaccount`}>
        Create Account
      </Link>
      <div style={{ marginTop: "10px" }}>Current user?</div>
      <Link className="pc-linkbutton" to={`login`}>
        Login
      </Link>
    </div>
  );
};

export default NotAuthenticatedPage;
