import "./../App.css";
import React, { useState } from "react";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Field, FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../shell/loading-spinner";
import { useRiderServiceProvider } from "../shell/rider-service-provider";
import { UserModel, UserModelWithError } from "../models/users.model";

var blankUser: UserModel = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  emailVerified: false,
  phoneNumber: "",
  isActive: true,
  isAdmin: false,
};

interface VolunteerEditPageProps {}

export const VolunteerEditPage: React.FunctionComponent<VolunteerEditPageProps> = () => {
  const { getDataApi } = useRiderServiceProvider();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [user, setUser] = useState<UserModel>(blankUser);

  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const requiredValidator = (value: string) => (value ? "" : "Error: This field is required.");
  const emailValidator = (value: string) =>
    value.length === 0 || emailRegex.test(value) ? "" : "Please enter a valid email.";

  const StringInput = (fieldRenderProps: { [x: string]: any; validationMessage: any; visited: any }) => {
    const { label, valid, disabled, id, validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </FieldWrapper>
    );
  };

  const SwitchInput = (fieldRenderProps: { [x: string]: any; validationMessage: any; visited: any }) => {
    const { label, valid, value, disabled, id, validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <Switch checked={value} {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </FieldWrapper>
    );
  };

  const handleSubmit = (dataItem: any) => {
    let userToPost = {
      id: user.id,
      ...dataItem,
    } as UserModel;

    getDataApi().then((dataApi) => {
      setSpinner(true);
      dataApi
        .post<UserModelWithError>(`volunteers`, userToPost)
        .then((res) => {
          if (userId !== undefined && userId !== "0") {
            navigate("/user/volunteers");
          } else {
            setSpinner(false);
            setUser(blankUser);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  React.useEffect(() => {
    if (userId !== undefined && user.id.toString() !== userId) {
      setSpinner(true);
      getDataApi().then((dataApi) => {
        dataApi
          .get<UserModel>(`volunteers/${userId}`)
          .then((res) => {
            setUser(res.data);
            setSpinner(false);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, user, userId]);

  if (spinner === true) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        isActive: user.isActive,
        isAdmin: user.isAdmin,
      }}
      render={(formRenderProps) => (
        <FormElement>
          <fieldset className={"k-form-fieldset"}>
            <div className="mb-3">
              <Field name={"firstName"} label={"First name"} validator={requiredValidator} component={StringInput} />
            </div>
            <div className="mb-3">
              <Field name={"lastName"} label={"Last name"} validator={requiredValidator} component={StringInput} />
            </div>
            <div className="mb-3">
              <Field name={"email"} type={"email"} label={"Email"} validator={emailValidator} component={StringInput} />
            </div>
            <div className="mb-3">
              <Field name={"isActive"} label={"Is Active"} component={SwitchInput} />
            </div>
            <div className="mb-3">
              <Field name={"isAdmin"} label={"Is Administrator"} component={SwitchInput} />
            </div>
          </fieldset>
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              margin: "6px",
              left: "0%",
              width: "100%",
              backgroundColor: "white",
              borderTopWidth: "1px",
              borderTopColor: "darkgray",
              borderTopStyle: "solid",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <button
                style={{ float: "right", marginRight: "15px", marginBottom: "1px", minWidth: "120px" }}
                className="pc-button"
                type="submit"
              >
                Save
              </button>
              {userId !== undefined && userId !== "0" && (
                <Link
                  style={{ float: "right", marginRight: "10px", minWidth: "120px" }}
                  className="pc-linkbutton"
                  to={`delete`}
                >
                  Delete
                </Link>
              )}
              <Link
                style={{ float: "right", marginRight: "10px", minWidth: "120px" }}
                className="pc-linkbutton"
                to={`/`}
              >
                {userId === "0" && <span>Back to List</span>}
                {userId !== "0" && <span>Cancel</span>}
              </Link>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};

export default VolunteerEditPage;
