import "./../App.css";
import React, { useState } from "react";
import { Input, MaskedTextBox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Field, FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../shell/loading-spinner";
import { DonationModel, DonationModelError } from "../models/donations.model";
import { useRiderServiceProvider } from "../shell/rider-service-provider";
import { useLocation } from "../shell/location-provider";

var blankDonation: DonationModel = {
  id: 0,
  firstName: "",
  lastName: "",
  amount: 0,
  email: "",
  phone: "",
  latitude: 0,
  longitude: 0,
  zipCode: "",
  donationDate: new Date(),
};

interface CollectEditPageProps {}

export const CollectEditPage: React.FunctionComponent<CollectEditPageProps> = () => {
  const { getDataApi } = useRiderServiceProvider();
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const { donationId } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [donation, setDonation] = useState<DonationModel>(blankDonation);

  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  const requiredValidator = (value: string) => (value ? "" : "Error: This field is required.");
  const emailValidator = (value: string) =>
    value.length === 0 || emailRegex.test(value) ? "" : "Please enter a valid email.";
  const amountValidator = (value: number) => (value >= 0 && value <= 100 ? "" : "Donations must be less the $100.");

  const StringInput = (fieldRenderProps: { [x: string]: any; validationMessage: any; visited: any }) => {
    const { label, valid, disabled, id, validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </FieldWrapper>
    );
  };

  const AmountInput = (fieldRenderProps: { [x: string]: any; validationMessage: any; visited: any }) => {
    const { label, valid, disabled, id, validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <NumericTextBox format={"c0"} spinners={false} required={true} {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </FieldWrapper>
    );
  };

  const PhoneInput = (fieldRenderProps: { [x: string]: any; validationMessage: any; visited: any }) => {
    const { label, valid, disabled, id, validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <MaskedTextBox mask="(000) 000-0000" {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </FieldWrapper>
    );
  };

  const ZipInput = (fieldRenderProps: { [x: string]: any; validationMessage: any; visited: any }) => {
    const { label, valid, disabled, id, validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <MaskedTextBox mask="00000" {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </FieldWrapper>
    );
  };

  const handleSubmit = (dataItem: any) => {
    let donationToPost = {
      id: donation.id,
      latitude: currentLocation.latitude,
      longitude: currentLocation.longitude,
      donationDate: new Date(),
      ...dataItem,
    } as DonationModel;

    getDataApi().then((dataApi) => {
      if (currentLocation === undefined || currentLocation.latitude === 0) {
        alert("Please allow location services to use this feature.");
      } else {
        setSpinner(true);
        dataApi
          .post<DonationModelError>(`user/donations`, donationToPost)
          .then((res) => {
            if (donationId !== undefined && donationId !== "0") {
              navigate("/user/donations");
            } else {
              setSpinner(false);
              setDonation({ ...blankDonation, donationDate: new Date() });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  React.useEffect(() => {
    if (donationId !== undefined && donation.id.toString() !== donationId) {
      setSpinner(true);
      getDataApi().then((dataApi) => {
        dataApi
          .get<DonationModel>(`user/donations/${donationId}`)
          .then((res) => {
            setDonation(res.data);
            setSpinner(false);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [getDataApi, donation, donationId]);

  if (spinner === true) {
    return <LoadingSpinner />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        firstName: donation.firstName,
        lastName: donation.lastName,
        email: donation.email,
        amount: donation.amount,
        phone: donation.phone,
        zipCode: donation.zipCode,
      }}
      render={(formRenderProps) => (
        <FormElement>
          <fieldset className={"k-form-fieldset"}>
            <div className="mb-3">
              <Field name={"firstName"} label={"First name"} validator={requiredValidator} component={StringInput} />
            </div>
            <div className="mb-3">
              <Field name={"lastName"} label={"Last name"} validator={requiredValidator} component={StringInput} />
            </div>
            <div className="mb-3">
              <Field name={"amount"} label={"Donation Amount"} validator={amountValidator} component={AmountInput} />
            </div>
            <div className="mb-3">
              <Field name={"email"} type={"email"} label={"Email"} validator={emailValidator} component={StringInput} />
            </div>
            <div className="mb-3">
              <Field name={"phone"} label={"Phone Number"} component={PhoneInput} />
            </div>
            <div className="mb-3">
              <Field name={"zipCode"} label={"Zip Code"} component={ZipInput} />
            </div>
          </fieldset>
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              margin: "6px",
              left: "0%",
              width: "100%",
              backgroundColor: "white",
              borderTopWidth: "1px",
              borderTopColor: "darkgray",
              borderTopStyle: "solid",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <button
                style={{ float: "right", marginRight: "15px", marginBottom: "1px", minWidth: "120px" }}
                className="pc-button"
                type="submit"
              >
                Save
              </button>
              {donationId !== undefined && donationId !== "0" && (
                <Link
                  style={{ float: "right", marginRight: "10px", minWidth: "120px" }}
                  className="pc-linkbutton"
                  to={`delete`}
                >
                  Delete
                </Link>
              )}
              <Link
                style={{ float: "right", marginRight: "10px", minWidth: "120px" }}
                className="pc-linkbutton"
                to={`/`}
              >
                {donationId === "0" && <span>Back to List</span>}
                {donationId !== "0" && <span>Cancel</span>}
              </Link>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};

export default CollectEditPage;
