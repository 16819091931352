import "./../App.css";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "./loading-spinner";

interface RootRedirectProps {}

export const RootRedirect: React.FunctionComponent<RootRedirectProps> = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading === true) {
    return <LoadingSpinner />;
  }
  if (isAuthenticated === false) {
    return <Navigate to={"/noauth"} />;
  }

  return <Navigate to={"/user"} />;
};

export default RootRedirect;
