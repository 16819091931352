class DeployEnvironment {
    constructor(public readonly apiUrl: string, public readonly authRedirectUri: string) {}
  }
  
  // This class exists to workaround bug in SWA where we cannot set environment variables to
  // SWA during deployment. As a workaround, we are hardcoding URLs here when in SWA environment.
  export class DeployConfig {
    private static readonly local: DeployEnvironment = {
      authRedirectUri: 'http://localhost:3000',
      apiUrl: 'https://localhost:7179/api/v1/',
    };
  
    private static readonly localSwa: DeployEnvironment = {
      authRedirectUri: 'http://localhost:4280',
      apiUrl: 'https://rider-api-proto.azurewebsites.net/api/v1',
    };
  
    private static readonly azureSwa: DeployEnvironment = {
      authRedirectUri: 'https://app.canvassnow.com',
      apiUrl: 'https://canvass-api.azurewebsites.net/api/v1',
    };
  
    static getCurrent() {
      const href = window.location.href.toLowerCase();
      if (href.includes(this.local.authRedirectUri.toLowerCase())) {
        return this.local;
      } else if (href.includes(this.localSwa.authRedirectUri.toLowerCase())) {
        return this.localSwa;
      } else return this.azureSwa;
    }
  }
  